import React, { useEffect, useState } from "react";
import Text from "Components/Text";
import { contentfulClient } from "Services"; // asumo que tenés configurado el client
import { documentToReactComponents } from "@contentful/rich-text-react-renderer";

// Colores que usás, etc...
import {
    arena,
    blue,
    darkGrey,
    important,
    information,
    newBackground,
    positive,
    secondary,
    violeta,
    white,
} from "Constants/colors";

// 1. Definimos el query de GraphQL
const getCondicionesQuery = (isTesting) => {
    const now = new Date().toISOString();
    return `
      query {
        termsConditionsCollection(
          where: {
            environment_contains_some: ["${isTesting ? 'testing' : 'production'}"]
            startDateTime_lt: "${now}"
            endDateTime_gte: "${now}"
          }
        ) {
          items {
            condiciones {
              json
            }
          }
        }
      }
    `;
};


// 2. Componente BaseAndCondition
export const BaseAndCondition = () => {
    const [richText, setRichText] = useState(null);
    const isTesting = window.location.host.indexOf("testing") > -1;

    useEffect(() => {
        const fetchCondiciones = async () => {
            try {
                const query = getCondicionesQuery(isTesting);
                const response = await contentfulClient.post("", { query });

                // Asumiendo que la respuesta vendrá en: response.data.data.condicionesBasesCollection.items
                const items = response?.data?.data?.termsConditionsCollection?.items;

                // Si encontramos contenido en items, tomamos el primero (o filtramos más si querés)
                if (items && items.length > 0) {
                    // Nos quedamos con la propiedad json del primer item
                    const { json } = items[0]?.condiciones || {};
                    // Guardamos en state para renderizar más abajo
                    if (json) {
                        setRichText(json);
                    }
                }
            } catch (error) {
                console.error("Error fetching condiciones from Contentful", error);
            }
        };

        fetchCondiciones();
    }, [isTesting]);

    // Si richText existe y es un objeto válido => renderizamos su contenido
    if (richText) {
        return (
            <div style={{ margin: "10px", paddingRight: "14px" }}>
                {documentToReactComponents(richText)}
            </div>
        );
    }

    // Caso contrario => renderizamos el "hardcodeado" por defecto
    return (
        <div style={{ margin: "10px", paddingRight: "14px" }}>
            <Text>
                <strong>Bases y Condiciones – Tienda Naranja Viajes</strong>
            </Text>

            <ol
                style={{
                    paddingTop: "12px",
                    display: "flex",
                    flexDirection: "column",
                    gap: "8px",
                }}
            >
                <li>
                    <Text paragraph alignment="justify">
                        <strong>ORGANIZADOR</strong>. Pont S.A., en adelante el{" "}
                        <strong>ORGANIZADOR</strong>, con RUC N° 80131394, domicilio situado en la
                        calle Santa Teresa casi Herminio Maldonado de la ciudad de Asunción,
                        teléfono +595 984 729 300, realizará las promociones denominadas en las
                        comunicaciones del sitio (banners) en Tienda Naranja Viajes en adelante la{" "}
                        <strong>PROMOCIÓN</strong>.
                    </Text>
                </li>
                <li>
                    <Text paragraph alignment="justify">
                        <strong>VIGENCIA</strong>. La PROMOCIÓN tendrá la vigencia detallada en dicho
                        banner y será válida para todo el territorio nacional.
                    </Text>
                </li>
                <li>
                    <Text paragraph alignment="justify">
                        <strong>PARTICIPANTES</strong>. Participan de la promoción todas las personas
                        físicas mayores de 18 años o jurídicas que cumplan los requisitos
                        establecidos en estas bases y condiciones.
                    </Text>
                </li>
                <li>
                    <Text paragraph alignment="justify">
                        <strong>CONDICIONES</strong>. Serán válidas todas las compras que se realicen
                        únicamente en la Plataforma Tienda Naranja Viajes (
                        <a
                            href="https://viajes.tiendanaranja.com.py"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            viajes.tiendanaranja.com.py
                        </a>
                        ) en adelante, “el Sitio” y que cumplan las siguientes condiciones:
                    </Text>
                    <br />
                    <Text paragraph alignment="justify">
                        <ol type="a">
                            <li>
                                Todas las compras hechas con tarjetas de crédito Itaú podés elegir
                                pagar con el beneficio de hasta 15 cuotas sin intereses. La cantidad
                                de cuotas la elegís al momento de la compra. Aplica para compras
                                realizadas a través del vPOS de Bancard. No incluye tarjetas
                                pre-pagas.
                                <ul>
                                    <li>
                                        Para compras en cuotas; en el extracto ingresará la cuotización
                                        (sin modificación) por el total de la compra.
                                    </li>
                                </ul>
                            </li>
                            <li>
                                Puntos de programas de recompensas Membership Rewards e Interventajas,
                                ya aplicados en la plataforma de Tienda Naranja Viajes con el beneficio
                                final por el valor de canje establecido en el banner durante la
                                promoción vigente (fechas detalladas en el sitio).
                            </li>
                            <li>
                                Mínimo de canje por cliente/programa es de 500 puntos.
                            </li>
                            <li>
                                Para acceder al canje, el cliente debe ingresar con sus accesos
                                personales de su cuenta Itaú al servicio de canje de puntos mediante la
                                plataforma de Tienda Naranja Viajes.
                            </li>
                            <li>
                                Vigencia de la promoción de canje con valor duplicado detallado en el
                                banner del sitio (si aplica la promoción).
                            </li>
                            <li>
                                Promociones válidas únicamente en Tienda Naranja Viajes (
                                <a
                                    href="https://viajes.tiendanaranja.com.py"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    viajes.tiendanaranja.com.py
                                </a>
                                ).
                            </li>
                            <li>
                                Beneficios no aplican en Tienda Naranja (
                                <a
                                    href="https://tiendanaranja.com.py/"
                                    target="_blank"
                                    rel="noopener noreferrer"
                                >
                                    tiendanaranja.com.py
                                </a>
                                ).
                            </li>
                            <li>
                                Tienda Naranja Viajes podrá cancelar las compras luego de verificar que
                                en las mismas se han utilizado procedimientos, datos o información en
                                incumplimiento a los términos y condiciones de uso del Sitio.
                            </li>
                            <li>
                                Ante sospechas de casos de fraude de cualquier tipo, Tienda Naranja
                                Viajes podrá validar la identidad del cliente y/o cualquier otra
                                información, reservándose el derecho de acudir ante las instancias
                                judiciales.
                            </li>
                        </ol>
                    </Text>
                </li>
                <li>
                    <Text paragraph alignment="justify">
                        <strong>INTERPRETACIÓN Y CONSULTAS</strong>. Para cualquier situación no
                        prevista en estas bases y condiciones serán aplicados los Términos y
                        Condiciones y la Política de Privacidad publicados en{" "}
                        <a
                            href="https://viajes.tiendanaranja.com.py"
                            target="_blank"
                            rel="noopener noreferrer"
                        >
                            viajes.tiendanaranja.com.py
                        </a>{" "}
                        y adicionalmente Tienda Naranja Viajes podrá resolver los reclamos y/o
                        situaciones a su solo arbitrio. Consultas adicionales podrán ser canalizadas a
                        través del Servicio de Atención al Cliente al número de WhatsApp +595984729300.
                    </Text>
                </li>
            </ol>
        </div>
    );
};
